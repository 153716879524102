import React, { useState } from 'react';
import { DOBField } from '../DOBField/DOBField';
import { AddressFields } from '../AddressFields/AddressFields';
import { LicenseFields } from '../LicenseFields/LicenseFields';
// import { useStore } from '@nanostores/react';
// import { $ValidationsArray, addValidationField, removeValidationField } from '../nanostores/ValidationsArray';

export const BeneficialOwnerForm = ({ beneficialOwner, typesOfOwnership, isNewBeneficialOwner, profileId, presignedUrl, form_authenticity_token }) => {

  const [formData, setFormData] = useState({
    profile_id: profileId || beneficialOwner.profile_id || '',
    first_name: beneficialOwner.first_name || '',
    middle_name: beneficialOwner.middle_name || '',
    last_name: beneficialOwner.last_name || '',
    suffix: beneficialOwner.suffix || '',
    date_of_birth: beneficialOwner.date_of_birth || '',
    type_of_ownership: beneficialOwner.type_of_ownership || '',
    ownership_percentage: beneficialOwner.ownership_percentage || '',
    type_of_address: beneficialOwner.type_of_address || '',
    street_address: beneficialOwner.street_address || '',
    address_line_2: beneficialOwner.address_line_2 || '',
    city: beneficialOwner.city || '',
    country: beneficialOwner.country || '',
    state: beneficialOwner.state || '',
    zip: beneficialOwner.zip || '',
    type_of_license: beneficialOwner.type_of_license || '',
    license_number: beneficialOwner.license_number || '',
    license_country: beneficialOwner.license_country || '',
    license_state: beneficialOwner.license_state || '',
    identification_expiration_date: beneficialOwner.identification_expiration_date || '',
  });

  // const validationsArray = useStore($ValidationsArray);
  const [errors, setErrors] = useState({});
  const [identificationFront, setIdentificationFront] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setIdentificationFront(e.target.files[0]);
  };

  const handleLicenseFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  }

  const handleAddressFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  }

  const handleDOBFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  }

  const validateForm = () => {
    const newErrors = {};
    // Required fields
    if (!formData.first_name) newErrors.first_name = "First Name is required";
    if (!formData.last_name) newErrors.last_name = "Last Name is required";
    if (!formData.date_of_birth) newErrors.date_of_birth = "Date of Birth is required";
    if (!formData.type_of_ownership) newErrors.type_of_ownership = "Type of Ownership is required";
    if (!formData.ownership_percentage) newErrors.ownership_percentage = "Ownership Percentage is required";
    if (!formData.type_of_address) newErrors.type_of_address = "Type of Address is required";
    if (!formData.street_address) newErrors.street_address = "Street Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State/Province is required";
    if (!formData.zip) newErrors.zip = "ZIP Code is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.type_of_license) newErrors.type_of_license = "Type of License is required";

    // Date of Birth >= 18 years ago
    const dob = new Date(formData.date_of_birth);
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (age < 18 || (age === 18 && monthDiff < 0)) {
      newErrors.date_of_birth = "Beneficial owner must be at least 18 years old";
    }

    // Ownership Percentage validation
    const ownershipPercentage = parseFloat(formData.ownership_percentage);
    if (isNaN(ownershipPercentage) || ownershipPercentage < 0 || ownershipPercentage > 100) {
      newErrors.ownership_percentage = "Ownership Percentage must be a number between 0 and 100";
    }

    // Identification validation
    if (formData.type_of_license) {
      if (!formData.license_number) newErrors.license_number = "License Number is required";
      if (!formData.identification_expiration_date) newErrors.identification_expiration_date = "Identification Expiration Date is required";

      // identification_expiration_date must be in the future
      const expirationDate = new Date(formData.identification_expiration_date);
      if (expirationDate <= today) {
        newErrors.identification_expiration_date = "Identification expiration date must be in the future";
      }
    }

    // Add more validations as needed
    setErrors(newErrors);

    // If there are any errors, scroll to #error_explanation
    if (Object.keys(newErrors).length > 0) {
      const errorExplanation = document.getElementById('form');
      if (errorExplanation) {
        errorExplanation.scrollIntoView({ behavior: 'smooth' });
      }
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSubmit = new FormData();

      // Append all form data
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSubmit.append(`beneficial_owner[${key}]`, value);
      });

      // Append the file if it exists
      if (identificationFront) {
        formDataToSubmit.append('beneficial_owner[identification_front]', identificationFront);
      }

      formDataToSubmit.append('authenticity_token', form_authenticity_token);

      const submitUrl = isNewBeneficialOwner
        ? '/beneficial_owners'
        : `/beneficial_owners/${beneficialOwner.id}`;

      // formDataToSubmit values iterated
      console.log('Form Data to Submit:', formDataToSubmit.getAll('beneficial_owner[first_name]'));

      const method = isNewBeneficialOwner ? 'POST' : 'PUT';

      fetch(submitUrl, {
        method: method,
        body: formDataToSubmit,
        headers: {
          'X-CSRF-Token': form_authenticity_token,
        },
      })
        .then(response => {
          console.log('Response:', response);
          if (!response.redirected) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          // Extract the 'Location' header
          const locationHeader = `/profiles/${profileId}`;

          // Check if the response is JSON
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => ({ data, locationHeader }));
          } else {
            // If it's not JSON, just return the location header
            return { locationHeader };
          }
        })
        .then(({ data, locationHeader }) => {
          if (data) {
            console.log('Submission successful', data);
          } else {
            console.log('Submission successful, no data returned');
          }
          // Redirect to the location provided in the response
          if (locationHeader) {
            window.location.href = locationHeader;
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setErrors({ submission: error.message || 'An error occurred while submitting the form.' });
        });
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit} className="form" id="form">
            {Object.keys(errors).length > 0 && (
              <div className="alert alert-danger">
                <h2>{Object.keys(errors).length} error(s) prevented this form from being submitted:</h2>
                <ul>
                  {Object.entries(errors).map(([key, value]) => (
                    <li key={key}>{value}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="row">
              <div className="col-6">
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="beneficial_owner_first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="beneficial_owner_first_name" className="form-label">First Name*</label>
                </div>
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="beneficial_owner_middle_name"
                    name="middle_name"
                    value={formData.middle_name}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="beneficial_owner_middle_name" className="form-label">Middle Name</label>
                </div>
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="beneficial_owner_last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="beneficial_owner_last_name" className="form-label">Last Name*</label>
                </div>
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="beneficial_owner_suffix"
                    name="suffix"
                    value={formData.suffix}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="beneficial_owner_suffix" className="form-label">Suffix</label>
                </div>
                <DOBField
                  selectedDateOfBirth={formData.date_of_birth}
                  onChange={handleDOBFieldChange}
                />
                <div className="form-floating mb-2">
                  <select
                    className="form-select form-select-lg"
                    id="beneficial_owner_type_of_ownership"
                    name="type_of_ownership"
                    value={formData.type_of_ownership}
                    onChange={handleInputChange}
                    required
                    style={{ fontSize: '1rem !important' }}
                  >
                    <option value="">Select Ownership Type</option>
                    {typesOfOwnership.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                  <label htmlFor="beneficial_owner_type_of_ownership" className="form-label">Type of Ownership*</label>
                </div>
                <div className="form-floating mb-2">
                  <input
                    type="number"
                    className="form-control"
                    id="beneficial_owner_ownership_percentage"
                    name="ownership_percentage"
                    value={formData.ownership_percentage}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="beneficial_owner_ownership_percentage" className="form-label">Ownership Percentage*</label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-floating mb-2">
                  <select
                    className="form-select form-select-lg"
                    id="beneficial_owner_type_of_address"
                    name="type_of_address"
                    value={formData.type_of_address}
                    onChange={handleInputChange}
                    required
                    style={{ fontSize: '1rem !important' }}
                  >
                    <option value="">Select Address Type</option>
                    <option value="Residential">Residential</option>
                  </select>
                  <label htmlFor="beneficial_owner_type_of_address" className="form-label">Type of Address*</label>
                </div>
                <AddressFields
                  selectedStreetAddress={formData.street_address}
                  selectedAddressLine2={formData.address_line_2}
                  selectedCity={formData.city}
                  selectedCountry={formData.country}
                  selectedState={formData.state}
                  selectedZip={formData.zip}
                  onChange={handleAddressFieldChange}
                />
              </div>
            </div>
            <div className="bg-light p-3 mt-3" style={{ border: '1px solid black' }}>
              <div className="form-group">
                <strong><label className="form-label">Form of Identification*</label></strong>
                <p>Please select one of the following valid forms of identification. At least one of the following is required.</p>
                <LicenseFields
                  selectedTypeOfLicense={formData.type_of_license}
                  selectedLicenseNumber={formData.license_number}
                  selectedLicenseCountry={formData.license_country}
                  selectedLicenseState={formData.license_state}
                  selectedIdentificationExpirationDate={formData.identification_expiration_date}
                  onChange={handleLicenseFieldChange}
                />
              </div>
              <div className="row pt-3">
                <p>It is required that you scan and provide an image of this identification.</p>
                <div className="col-6">
                  <div className="form-group">
                    <strong><label htmlFor="beneficial_owner_identification_front" className="form-label">Identification Front{!presignedUrl && '*'}</label></strong>
                    <p><small>The image must be less than 4MB and in JPEG, PNG or PDF format.</small></p>
                    <input
                      type="file"
                      className="form-control"
                      id="beneficial_owner_identification_front"
                      name="identification_front"
                      onChange={handleFileChange}
                      required={!presignedUrl}
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                    />
                  </div>
                  {presignedUrl && (
                    <div className="form-group mt-3">
                      <strong>Current Identification Front:</strong>
                      <div className="mt-2">
                        <img
                          src={presignedUrl}
                          alt="Current Identification Front"
                          style={{ width: '500px' }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center my-3">
              <button type="submit" className="btn btn-primary">Save Beneficial Owner</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BeneficialOwnerForm;