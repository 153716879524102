// React field to mimic the following. If the date is after 2024, then the field should say how many days left until the filing deadline. The filing deadline is 90 dayys after the date of incorproration. If the deadline has passed, say so.
{/* <div class="form-floating mb-2">
  <%= form.date_field :date_of_incorporation, class: "form-control", required: true %>
  <%= form.label :date_of_incorporation, class: "control-label" %>
</div> */}
import React from 'react';

interface DOIFieldProps {
  modelName: string;
  selectedDOI?: string;
}

export const DOIField = ({ modelName, selectedDOI }: DOIFieldProps) => {
  const [dateOfIncorporation, setDateOfIncorporation] = React.useState<string>(selectedDOI || '');

  const dateOfIncorporationDate = new Date(dateOfIncorporation);
  const filingDeadline = new Date(dateOfIncorporationDate);
  filingDeadline.setDate(filingDeadline.getDate() + 90);

  const today = new Date();
  const daysLeft = Math.floor((filingDeadline.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

  return (
    <div className="doi-field">
      <div className="form-floating mb-2">
        <input type="date"
          placeholder="Date of Incorporation"
          name={`${modelName}[date_of_incorporation]`}
          value={dateOfIncorporation}
          onChange={(e) => setDateOfIncorporation(e.target.value)}
          className="form-control"
          required={"required"}
        />
        <label htmlFor={`${modelName}[date_of_incorporation]`}>
          Date of Incorporation
        </label>
      </div>

      {dateOfIncorporationDate > new Date('2024-01-01')
        ? daysLeft > 0
          ? <p className="alert alert-warning" role="alert">
            {`${daysLeft} days left until the filing deadline for your company.`}
          </p>
          : <p className="alert alert-warning" role="alert">
            {`The filing deadline has passed ${daysLeft * -1} days ago for your company.`}
          </p>
        : ''}
    </div >
  );
};