import React from 'react';
// Component for Date of Birth Field that warns that the date of birth must be 18 years old if the user is under 18

export const DOBField = ({
  modelName,
  fieldName = 'date_of_birth',
  selectedDateOfBirth,
  errorMessage = 'Company applicant must be at least 18 years old.',
  onChange = () => { }
}) => {
  // Parse string selectedDateOfBirth (YYYY-MM-DD) to Date object
  const [dateOfBirth, setDateOfBirth] = React.useState(selectedDateOfBirth);
  const handleChange = (e) => setDateOfBirth(e.target.value);
  const age = new Date().getFullYear() - new Date(dateOfBirth).getFullYear();
  const name = `${modelName}[${fieldName}]`;

  if (window["CTAFormValidation"] === undefined) {
    window["CTAFormValidation"] = [];
  }

  if (age < 18) {
    window["CTAFormValidation"].push(name);
  } else {
    window["CTAFormValidation"] = window["CTAFormValidation"].filter((field) => field !== name);
  }

  React.useEffect(() => {
    onChange(fieldName, dateOfBirth);
  }, [dateOfBirth]);

  return (
    <div class="form-floating mb-2">
      <input type="date" id={name} name={name} onChange={handleChange} className="form-control" value={dateOfBirth} />
      <label htmlFor={name} className="form-label">Date of Birth*</label>
      {age < 18 && <div className="alert alert-warning">{errorMessage}</div>}
    </div>
  );
}