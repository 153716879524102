import React from 'react';

interface StateFieldProps {
  states: Array<string> | undefined;
  selectedState: string | null;
  modelName: string;
  fieldName: string;
  setStateFunction?: (state: string) => void;
  prefix?: string;
}

export const StateField = ({
  states = [],
  selectedState,
  modelName,
  fieldName = 'state',
  setStateFunction,
  prefix = ''
}: StateFieldProps) => {
  // If states is an empty array, render a text field that is not required, otherwise render a select field from states that is required
  const selectedStateObject = states.find(state => state === selectedState);

  return (
    <div class="form-floating mb-2">
      {states.length === 0 ? (
        <input
          type="text"
          placeholder="State/Province"
          name={`${modelName}[${prefix}${fieldName}]`}
          value={selectedState}
          onChange={(e) => setStateFunction && setStateFunction(e.target.value)} className="form-control"
        />
      ) : (
        <select
          name={`${modelName}[${prefix}${fieldName}]`}
          className="form-select"
          onChange={(e) => setStateFunction && setStateFunction(e.target.value)} required>
          <option value=""></option>
          {states.map((state, index) => (
            <option
              key={index}
              value={state}
              selected={`${state === selectedStateObject ? 'selected' : ''}`}
            >
              {state}
            </option>
          ))}
        </select>
      )}
      <label for={`${modelName}[${prefix}state]`}>State/Province*</label>
    </div>
  );
}