import React from 'react';
import { CTARepInfoBox, ReportingCompanyInfoBox } from '../InfoBox/InfoBox';
import OnboardingExemptionCheck from './OnboardingExemptionCheck';

// Name of Reporting Company
export const NameOfReportingCompany = {
  label: 'Name of Reporting Company',
  name: 'company_information[company_name]',
  errorConditions: [
    {
      errorContent: 'Please enter the name of the reporting company.',
      condition: (value: string) => value.length === 0
    },
  ],
  questionType: 'text'
}

// Date of Incorporation
export const DateOfIncorporation = {
  label: 'Date of Incorporation/Formation',
  name: 'company_information[date_of_incorporation]',
  errorConditions: [
    {
      errorContent: 'Please enter a valid date.',
      condition: (value: string) => !Date.parse(value)
    }
  ],
  questionType: 'date'
}

// Date of Incorporation Warning
export const DateOfIncorporationWarning = (state) => (
  {
    content: (
      <div className="alert alert-danger mt-2">
        Notice: Based on the information provided, there is less than 30 days before the Reporting Company's 90-day filing deadline. Due to the limited time before such deadline, please be advised that the Teeple Hall, LLP CTA Portal cannot guarantee that it will file the Reporting Company's initial BOl Report in before the date of such deadline.
      </div>
    ),
    questionType: 'jsx',
    showConditions: [
      {
        showCondition: () => {
          // Is there less than 30 days between today and ninety days after the date of incorporation?

          const date_of_incorporation = new Date(state['company_information[date_of_incorporation]']);
          const today = new Date();
          const ninety_days_after_incorporation = new Date(date_of_incorporation);
          ninety_days_after_incorporation.setDate(date_of_incorporation.getDate() + 90);
          const remainingDays = Math.floor((ninety_days_after_incorporation.getTime() - today.getTime()) / (1000 * 3600 * 24));
          console.log("Remaining Days", remainingDays)
          return remainingDays < 30 && date_of_incorporation.getFullYear() >= 2024;
        }
      }
    ]
  }
)

// Date of Incorporation Confirmation Checkbox
export const DateOfIncorporationWarningCheckbox = (state) => (
  {
    label: 'I acknowledge this notice.',
    name: 'company_information[date_of_incorporation_confirmation]',
    errorConditions: [
      {
        errorContent: 'Please confirm that the date of incorporation/formation is accurate.',
        condition: (value: boolean) => value === false
      }
    ],
    questionType: 'checkbox',
    showConditions: [
      // Notice: Based on the information provided, there is less than 30 days before the Reporting Company's 90-day filing deadline. Due to the limited time before such deadline, please be advised that the Teeple Hall, LLP CTA Portal cannot guarantee that it will file the Reporting Company's initial BOl Report in before the date of such deadline.
      {
        showCondition: () => {
          // Is there less than 30 days between today and ninety days after the date of incorporation?

          const date_of_incorporation = new Date(state['company_information[date_of_incorporation]']);
          const today = new Date();
          const ninety_days_after_incorporation = new Date(date_of_incorporation);
          ninety_days_after_incorporation.setDate(date_of_incorporation.getDate() + 90);
          const remainingDays = Math.floor((ninety_days_after_incorporation.getTime() - today.getTime()) / (1000 * 3600 * 24));
          return remainingDays < 30 && date_of_incorporation.getFullYear() >= 2024;
        }
      }
    ]
  }
)

// CTA Compliance Representative Information Header
export const CTAComplianceRepresentativeInformationHeader = {
  questionType: 'jsx',
  content: (
    <div className="mt-4 mb-2 text-center">
      <h3 className="d-inline-block">CTA Compliance Representative Information</h3>
      <CTARepInfoBox />
    </div>
  )
}

// Compliance Representative First Name
export const ComplianceRepresentativeFirstName = {
  label: 'First Name',
  name: 'profile[rep_first_name]',
  errorConditions: [
    {
      errorContent: 'Please enter the first name of the compliance representative.',
      condition: (value: string) => value.length === 0,
      blocking: true
    },
  ],
  questionType: 'text'
}

// Compliance Representative Middle Name
export const ComplianceRepresentativeMiddleName = {
  label: 'Middle Name',
  name: 'profile[rep_middle_name]',
  errorConditions: [],
  questionType: 'text'
}

// Compliance Representative Last Name
export const ComplianceRepresentativeLastName = {
  label: 'Last Name',
  name: 'profile[rep_last_name]',
  errorConditions: [
    {
      errorContent: 'Please enter the last name of the compliance representative.',
      condition: (value: string) => value.length === 0,
      blocking: true
    },
  ],
  questionType: 'text'
}

// Rep Suffix
export const ComplianceRepresentativeSuffix = {
  label: 'Suffix',
  name: 'profile[rep_suffix]',
  errorConditions: [],
  questionType: 'text'
}

// Reporting Company Header
export const ReportingCompanyHeader = {
  questionType: 'jsx',
  content: (
    <div className="mt-4 mb-2 text-center">
      <h3 className="d-inline-block">Reporting Company</h3>
      <ReportingCompanyInfoBox />
    </div>
  )
}

// Type of Entity
// Options: Corporation / Delaware Statutory Trust / LLC / LLP / LP / Wyoming Statutory Foundation / Wyoming Statutory Trust / Other
export const TypeOfEntity = {
  label: 'Type of Entity',
  name: 'company_information[type_of_company]',
  questionType: 'select',
  options: [
    { value: 'Corporation', label: 'Corporation' },
    { value: 'Delaware Statutory Trust', label: 'Delaware Statutory Trust' },
    { value: 'LLC', label: 'LLC' },
    { value: 'LLP', label: 'LLP' },
    { value: 'LP', label: 'LP' },
    { value: 'Wyoming Statutory Foundation', label: 'Wyoming Statutory Foundation' },
    { value: 'Wyoming Statutory Trust', label: 'Wyoming Statutory Trust' },
    { value: 'Other', label: 'Other' }
  ],
  errorConditions: [],
}

// Type of Entity Other
export const TypeOfEntityOther = (state) => (
  {
    label: 'Enter Type of Entity',
    name: 'company_information[other_text]',
    errorConditions: [],
    questionType: 'text',
    showConditions: [
      {
        showCondition: () => state['company_information[type_of_company]'] === 'Other'
      }
    ]
  }
)

// Entity Create By Secretary of State
export const EntityCreateBySoS = (state) => (
  {
    label: "Was the entity created by the filing of a document with a secretary of state or any similar office under the law of a State or Indian tribe (or if a foreign entity, registered to do business in any State or tribal jurisdiction by the filing of a document with a secretary of state or any similar office under the law of a State or Indian tribe)?”",
    name: 'company_information[formed_by_filing]',
    errorConditions: [
      {
        errorContent: 'An entity must be created/incorporated as described above to be a “Reporting Company” within the meaning of the CTA.  Given this information, please confirm whether you would like to continue.',
        condition: (value: string) => value === 'false'
      }
    ],
    questionType: 'select',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' }
    ],
    showConditions: [
      {
        showCondition: () => state['company_information[type_of_company]'] && state['company_information[type_of_company]'] === 'Other'
      }
    ]
  }
)

// Confirm "I would like to continue"
export const ConfirmContinue = (state) => (
  {
    label: 'Given this information, please confirm whether you would like to continue.',
    name: 'company_information[confirm_continue]',
    questionType: 'checkbox',
    showConditions: [
      {
        showCondition: () => state['company_information[formed_by_filing]'] === 'false'
      }
    ]
  }
)

// Exemption Question
export const ExemptionQuestion = {
  label: 'Does the Reporting Company qualify for any exemptions listed in the CTA?  See below for a complete list of exemptions.  Please note that the exemption categories are generally applicable to government agencies, regulated businesses, and large private operating companies.',
  name: 'company_information[has_exemptions]',
  errorConditions: [],
  questionType: 'select',
  options: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: "I'm Not Sure", label: "I'm Not Sure" }
  ]
}

// Exemption Will Be Collected Later
export const ExemptionWillBeCollectedLater = (state) => (
  {
    questionType: 'jsx',
    content: (
      <div className="alert alert-info mt-2">
        If an exemption applies to the Reporting Company, such information will be obtained later.
      </div>
    ),
    showConditions: [
      {
        showCondition: () => state['company_information[has_exemptions]'] === 'Yes'
      }
    ]
  }
)

// You will need to make a determination
export const ExemptionDetermination = (state) => (
  {
    questionType: 'jsx',
    content: (
      <div className="alert alert-info mt-2">
        You will need to make this determination as part of the CTA process.  CTA Portal services do not include such analysis.
      </div>
    ),
    showConditions: [
      {
        showCondition: () => state['company_information[has_exemptions]'] === "I'm Not Sure"
      }
    ]
  }
)

export const ExemptionsList = {
  questionType: 'jsx',
  content: <>
    <div className="col-md-12">
      {/* height: 500px; overflow-y: scroll; border: 1px solid #ccc; padding: 10px; margin-top: 20px; */}
      <div style={{
        height: '500px',
        overflowY: 'scroll',
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px'
      }}>
        <h4>31 CFR 1010.380(c)(2)(i) through (xxiii)</h4>
        <p>“(2) Exemptions. Notwithstanding paragraph (c)(1) of this section, the term “reporting company” does not include:</p>
        <ol>
          <li><strong>Securities reporting issuer.</strong> Any issuer of securities that is:
            <ul>
              <li>(A) An issuer of a class of securities registered under section 12 of the Securities Exchange Act of 1934 (15 U.S.C. 78l); or</li>
              <li>(B) Required to file supplementary and periodic information under section 15(d) of the Securities Exchange Act of 1934 (15 U.S.C. 78o(d)).</li>
            </ul>
          </li>
          <li><strong>Governmental authority.</strong> Any entity that:
            <ul>
              <li>(A) Is established under the laws of the United States, an Indian tribe, a State, or a political subdivision of a State, or under an interstate compact between two or more States; and</li>
              <li>(B) Exercises governmental authority on behalf of the United States or any such Indian tribe, State, or political subdivision.</li>
            </ul>
          </li>
          <li><strong>Bank.</strong> Any bank, as defined in:
            <ul>
              <li>(A) Section 3 of the Federal Deposit Insurance Act (12 U.S.C. 1813);</li>
              <li>(B) Section 2(a) of the Investment Company Act of 1940 (15 U.S.C. 80a-2(a)); or</li>
              <li>(C) Section 202(a) of the Investment Advisers Act of 1940 (15 U.S.C. 80b-2(a)).</li>
            </ul>
          </li>
          <li><strong>Credit union.</strong> Any Federal credit union or State credit union, as those terms are defined in section 101 of the Federal Credit Union Act (12 U.S.C. 1752).</li>
          <li><strong>Depository institution holding company.</strong> Any bank holding company as defined in section 2 of the Bank Holding Company Act of 1956 (12 U.S.C. 1841), or any savings and loan holding company as defined in section 10(a) of the Home Owners' Loan Act (12 U.S.C. 1467a(a)).</li>
          <li><strong>Money services business.</strong> Any money transmitting business registered with FinCEN under 31 U.S.C. 5330, and any money services business registered with FinCEN under 31 CFR 1022.380.</li>
          <li><strong>Broker or dealer in securities.</strong> Any broker or dealer, as those terms are defined in section 3 of the Securities Exchange Act of 1934 (15 U.S.C. 78c), that is registered under section 15 of that Act (15 U.S.C. 78o).</li>
          <li><strong>Securities exchange or clearing agency.</strong> Any exchange or clearing agency, as those terms are defined in section 3 of the Securities Exchange Act of 1934 (15 U.S.C. 78c), that is registered under sections 6 or 17A of that Act (15 U.S.C. 78f, 78q-1).</li>
          <li><strong>Other Exchange Act registered entity.</strong> Any other entity not described in paragraph (c)(2)(i), (vii), or (viii) of this section that is registered with the Securities and Exchange Commission under the Securities Exchange Act of 1934 (15 U.S.C. 78a et seq.).</li>
          <li><strong>Investment company or investment adviser.</strong> Any entity that is:
            <ul>
              <li>(A) An investment company as defined in section 3 of the Investment Company Act of 1940 (15 U.S.C. 80a-3), or is an investment adviser as defined in section 202 of the Investment Advisers Act of 1940 (15 U.S.C. 80b-2); and</li>
              <li>(B) Registered with the Securities and Exchange Commission under the Investment Company Act of 1940 (15 U.S.C. 80a-1 et seq.) or the Investment Advisers Act of 1940 (15 U.S.C. 80b-1 et seq.).</li>
            </ul>
          </li>
          <li><strong>Venture capital fund adviser.</strong> Any investment adviser that:
            <ul>
              <li>(A) Is described in section 203(l) of the Investment Advisers Act of 1940 (15 U.S.C. 80b-3(l)); and</li>
              <li>(B) Has filed Item 10, Schedule A, and Schedule B of Part 1A of Form ADV, or any successor thereto, with the Securities and Exchange Commission.</li>
            </ul>
          </li>
          <li><strong>Insurance company.</strong> Any insurance company as defined in section 2 of the Investment Company Act of 1940 (15 U.S.C. 80a-2).</li>
          <li><strong>State-licensed insurance producer.</strong> Any entity that:
            <ul>
              <li>(A) Is an insurance producer that is authorized by a State and subject to supervision by the insurance commissioner or a similar official or agency of a State; and</li>
              <li>(B) Has an operating presence at a physical office within the United States.</li>
            </ul>
          </li>
          <li><strong>Commodity Exchange Act registered entity.</strong> Any entity that:
            <ul>
              <li>(A) Is a registered entity as defined in section 1a of the Commodity Exchange Act (7 U.S.C. 1a); or</li>
              <li>(B) Is:
                <ol>
                  <li>A futures commission merchant, introducing broker, swap dealer, major swap participant, commodity pool operator, or commodity trading advisor, each as defined in section 1a of the Commodity Exchange Act (7 U.S.C. 1a), or a retail foreign exchange dealer as described in section 2(c)(2)(B) of the Commodity Exchange Act (7 U.S.C. 2(c)(2)(B)); and</li>
                  <li>Registered with the Commodity Futures Trading Commission under the Commodity Exchange Act.</li>
                </ol>
              </li>
            </ul>
          </li>
          <li><strong>Accounting firm.</strong> Any public accounting firm registered in accordance with section 102 of the Sarbanes-Oxley Act of 2002 (15 U.S.C. 7212).</li>
          <li><strong>Public utility.</strong> Any entity that is a regulated public utility as defined in 26 U.S.C. 7701(a)(33)(A) that provides telecommunications services, electrical power, natural gas, or water and sewer services within the United States.</li>
          <li><strong>Financial market utility.</strong> Any financial market utility designated by the Financial Stability Oversight Council under section 804 of the Payment, Clearing, and Settlement Supervision Act of 2010 (12 U.S.C. 5463).</li>
          <li><strong>Pooled investment vehicle.</strong> Any pooled investment vehicle that is operated or advised by a person described in paragraph (c)(2)(iii), (iv), (vii), (x), or (xi) of this section.</li>
          <li><strong>Tax-exempt entity.</strong> Any entity that is:
            <ul>
              <li>(A) An organization that is described in section 501(c) of the Internal Revenue Code of 1986 (Code) (determined without regard to section 508(a) of the Code) and exempt from tax under section 501(a) of the Code, except that in the case of any such organization that ceases to be described in section 501(c) and exempt from tax under section 501(a), such organization shall be considered to continue to be described in this paragraph (c)(1)(xix)(A) for the 180-day period beginning on the date of the loss of such tax-exempt status;</li>
              <li>(B) A political organization, as defined in section 527(e)(1) of the Code, that is exempt from tax under section 527(a) of the Code; or</li>
              <li>(C) A trust described in paragraph (1) or (2) of section 4947(a) of the Code.</li>
            </ul>
          </li>
          <li><strong>Entity assisting a tax-exempt entity.</strong> Any entity that:
            <ul>
              <li>(A) Operates exclusively to provide financial assistance to, or hold governance rights over, any entity described in paragraph (c)(2)(xix) of this section;</li>
              <li>(B) Is a United States person;</li>
              <li>(C) Is beneficially owned or controlled exclusively by one or more United States persons that are United States citizens or lawfully admitted for permanent residence; and</li>
              <li>(D) Derives at least a majority of its funding or revenue from one or more United States persons that are United States citizens or lawfully admitted for permanent residence.</li>
            </ul>
          </li>
          <li><strong>Large operating company.</strong> Any entity that:
            <ul>
              <li>(A) Employs more than 20 full time employees in the United States, with “full time employee in the United States” having the meaning provided in 26 CFR 54.4980H-1(a) and 54.4980H-3, except that the term “United States” as used in 26 CFR 54.4980H-1(a) and 54.4980H-3 has the meaning provided in § 1010.100(hhh);</li>
              <li>(B) Has an operating presence at a physical office within the United States; and</li>
              <li>(C) Filed a Federal income tax or information return in the United States for the previous year demonstrating more than $5,000,000 in gross receipts or sales, as reported as gross receipts or sales (net of returns and allowances) on the entity's IRS Form 1120, consolidated IRS Form 1120, IRS Form 1120-S, IRS Form 1065, or other applicable IRS form, excluding gross receipts or sales from sources outside the United States, as determined under Federal income tax principles. For an entity that is part of an affiliated group of corporations within the meaning of 26 U.S.C. 1504 that filed a consolidated return, the applicable amount shall be the amount reported on the consolidated return for such group.</li>
            </ul>
          </li>
          <li><strong>Subsidiary of certain exempt entities.</strong> Any entity whose ownership interests are controlled or wholly owned, directly or indirectly, by one or more entities described in paragraphs (c)(2)(i), (ii), (iii), (iv), (v), (vii), (viii), (ix), (x), (xi), (xii), (xiii), (xiv), (xv), (xvi), (xvii), (xix), or (xxi) of this section.</li>
          <li><strong>Inactive entity.</strong> Any entity that:
            <ul>
              <li>(A) Was in existence on or before January 1, 2020;</li>
              <li>(B) Is not engaged in active business;</li>
              <li>(C) Is not owned by a foreign person, whether directly or indirectly, wholly or partially;</li>
              <li>(D) Has not experienced any change in ownership in the preceding twelve month period;</li>
              <li>(E) Has not sent or received any funds in an amount greater than $1,000, either directly or through any financial account in which the entity or any affiliate of the entity had an interest, in the preceding twelve month period; and</li>
              <li>(F) Does not otherwise hold any kind or type of assets, whether in the United States or abroad, including any ownership interest in any corporation, limited liability company, or other similar entity.</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </>
}

export const HighRiskCountries = {
  questionType: 'select',
  name: 'company_information[high_risk_countries]',
  label: 'Does any Person (“Person” includes an individual, trust, estate, partnership, association, company, corporation, or other entity) directly or indirectly associated with the Reporting Company as an owner (of any percentage) or in a position of control (officer, manager, etc.) hold a passport and/or reside in any one or more of the following countries?',
  options: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ],
  errorConditions: [
    {
      errorContent: 'Teeple Hall, LLP is unable to accept an engagement for this Reporting Company.',
      condition: (value: string) => value === 'Yes'
    }
  ]
}

export const HighRiskCountriesList = {
  questionType: 'jsx',
  content: <>
    <div className="col-md-12">
      {/* height: 500px; overflow-y: scroll; border: 1px solid #ccc; padding: 10px; margin-top: 20px; */}
      <div style={{
        height: '500px',
        overflowY: 'scroll',
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px'
      }}>
        {/* Ordered list with roman numerals */}
        <ol type="i">
          <li>Bolivarian Republic of Venezuela</li>
          <li>Central African Republic</li>
          <li>Democratic People’s Republic of Korea</li>
          <li>Democratic Republic of the Congo</li>
          <li>Federal Democratic Republic of Ethiopia</li>
          <li>Federal Republic of Somalia</li>
          <li>Islamic Republic of Afghanistan</li>
          <li>Islamic Republic of Iran</li>
          <li>Lebanese Republic</li>
          <li>People's Republic of China</li>
          <li>Republic of Belarus</li>
          <li>Republic of Cuba</li>
          <li>Republic of Iraq</li>
          <li>Republic of Mali</li>
          <li>Republic of Nicaragua</li>
          <li>Republic of South Sudan</li>
          <li>Republic of the Union of Myanmar</li>
          <li>Republic of Yemen</li>
          <li>Russian Federation</li>
          <li>Syrian Arab Republic</li>
        </ol>
      </div>
    </div>
  </>
}

// OFAC Question
export const OFACQuestion = {
  label: "Is any Person (defined above) associated with the ownership or control of the Reporting Company a specifically designated national, politically exposed person, or covered by any Office of Foreign Assets Control (“OFAC”) sanctions?",
  name: 'company_information[has_any_foreign_beneficial_owner]',
  errorConditions: [
    {
      condition: (value: string) => value === "Yes",
      errorContent: "Teeple Hall, LLP is unable to accept an engagement for this Reporting Company."
    }
  ],
  questionType: 'select',
  options: [
    { value: "Yes", label: 'Yes' },
    { value: "No", label: 'No' }
  ]
}

// Does any trust or entity own 25% or more...
export const TrustOwnsCompany = {
  label: 'Does any trust or entity directly or indirectly own 25% or more of the Reporting Company or have any control over the Reporting Company?',
  name: 'company_information[trust_owns_company]',
  questionType: 'select',
  options: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: "I'm Not Sure", label: "I'm Not Sure" }
  ],
  alertType: 'info',
  errorConditions: [
    {
      errorContent: 'CTA FinCEN reporting requires the identification and reporting of natural persons as beneficial owners.  The Reporting Company will need to look through the trust or entity to determine the natural person or persons who are the Beneficial Owners.',
      condition: (value: string) => value === 'Yes'
    },
    {
      errorContent: 'CTA FinCEN reporting requires the identification and reporting of natural persons as beneficial owners.  The Reporting Company will need to look through the trust or entity to determine the natural person or persons who are the Beneficial Owners.',
      condition: (value: string) => value === "I'm Not Sure",
    }
  ]
}
// TODO: OK Option

export const SubmitButton = (state) => (
  {
    questionType: 'jsx',
    content: (
      <div className="text-center">
        <button type="submit" className="btn btn-primary">Submit</button>
      </div>
    ),
    showConditions: [
      {
        showCondition: function () {
          return state['company_information[type_of_company]'] && state['company_information[type_of_company]'] !== 'Trust';
        }
      },
      // {
      //   showCondition: function () {
      //     return state['company_information[has_exemptions]'] && state['company_information[has_exemptions]'] === 'No';
      //   }
      // },
      {
        showCondition: function () {
          return state['company_information[has_any_foreign_beneficial_owner]'].length > 0 && state['company_information[has_any_foreign_beneficial_owner]'] === 'No';
        }
      }
    ]
  }
)

// Exemptions List Radios
export const ExemptionsListRadios = (state, setState) => {
  console.log('state', state, setState);
  return {
    questionType: 'jsx',
    name: 'company_information[exemption_reason]',
    content: (
      <div>
        <OnboardingExemptionCheck
          setState={setState}
          selectedValue={state ? state['company_information[exemption_reason]'] : ''}
          disabled={!(state && state['company_information[has_exemptions]'] === 'Yes')}
        />
      </div>
    ),
  }
}

// Company Street Address
export const CompanyStreetAddress = {
  label: 'Company Street Address',
  name: 'company_information[street_address]',
  errorConditions: [
    {
      errorContent: 'Please enter the street address of the reporting company.',
      condition: (value: string) => value.length === 0
    }
  ],
  questionType: 'text'
}

// Company Street Address 2
export const CompanyStreetAddress2 = {
  label: 'Company Street Address 2',
  name: 'company_information[street_address_2]',
  errorConditions: [],
  questionType: 'text'
}

// Company City
export const CompanyCity = {
  label: 'City',
  name: 'company_information[city]',
  errorConditions: [
    {
      errorContent: 'Please enter the city of the reporting company.',
      condition: (value: string) => value.length === 0
    }
  ],
  questionType: 'text'
}

// Company State
export const CompanyState = {
  label: 'State',
  name: 'company_information[state]',
  errorConditions: [
    {
      errorContent: 'Please enter the state of the reporting company.',
      condition: (value: string) => value.length === 0
    }
  ],
  questionType: 'text'
}

// Company Zip
export const CompanyZip = {
  label: 'Zip',
  name: 'company_information[zip]',
  errorConditions: [
    {
      errorContent: 'Please enter the zip code of the reporting company.',
      condition: (value: string) => value.length === 0
    }
  ],
  questionType: 'text'
}