import React from 'react';
import { InputMask, type MaskEventDetail } from '@react-input/mask';

const TaxIDTypeField = ({
  modelName,
  fieldName = 'tax_id_type',
  selectedTaxIDType,
  setTaxIDType
}) => {
  const name = `${modelName}[${fieldName}]`;

  const handleChange = (e) => {
    setTaxIDType(e.target.value);
  }

  return (
    <div className="form-floating mb-2">
      <select id={name} name={name} className="form-select" value={selectedTaxIDType} onChange={handleChange}>
        <option value="EIN">EIN</option>
        <option value="SSN/ITIN">SSN/ITIN</option>
        <option value="Foreign">Foreign</option>
      </select>
      <label htmlFor={name} className="form-label">Tax ID Type*</label>
    </div>
  );
}

const TaxIDNumberField = ({
  modelName,
  fieldName = 'tax_id_number',
  selectedTaxIDNumber,
  selectedTaxIDType,
  setTaxIDNumber,
}) => {
  const name = `${modelName}[${fieldName}]`;

  const handleChange = (e) => {
    setTaxIDNumber(e.target.value);
  }

  let inputMask = '';
  if (selectedTaxIDType === 'EIN') {
    inputMask = '__-_______';
  } else if (selectedTaxIDType === 'SSN/ITIN') {
    inputMask = '___-__-____';
  } else if (selectedTaxIDType === 'Foreign') {
    inputMask = '';
  }

  // React.useEffect(() => {
  //   setTaxIDNumber(selectedTaxIDNumber);
  // }, [selectedTaxIDType]);

  return (
    <div className="form-floating mb-2" key={inputMask}>
      {selectedTaxIDType === 'Foreign' && (
        <input
          id={name}
          name={name}
          onChange={handleChange}
          className="form-control"
          value={selectedTaxIDNumber}
        />
      )}
      {selectedTaxIDType !== 'Foreign' && (
        <InputMask
          mask={inputMask}
          replacement={'_'}
          id={name}
          name={name}
          onChange={handleChange}
          className="form-control"
          value={selectedTaxIDNumber}
        />
      )}
      <label htmlFor={name} className="form-label">Tax Identification Number*</label>
    </div>
  );
}

export const EINField = ({
  modelName,
  selectedTaxIDType,
  selectedTaxIDNumber,
  taxIDFieldTypeName = 'tax_id_type',
  taxIDFieldName = 'tax_id_number'
}) => {
  const [taxIDType, setTaxIDType] = React.useState(selectedTaxIDType || 'EIN');
  const [taxIDNumber, setTaxIDNumber] = React.useState(selectedTaxIDNumber);

  return (
    <>
      <TaxIDTypeField
        modelName={modelName}
        selectedTaxIDType={taxIDType}
        fieldName={taxIDFieldTypeName}
        setTaxIDType={setTaxIDType}
      />
      <TaxIDNumberField
        modelName={modelName}
        selectedTaxIDNumber={taxIDNumber}
        fieldName={taxIDFieldName}
        setTaxIDNumber={setTaxIDNumber}
        selectedTaxIDType={taxIDType}
      />
    </>
  );
}