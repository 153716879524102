import React from 'react';

import { QuestionTextField } from '../Onboarding/QuestionTextField';
import { QuestionDateField } from '../Onboarding/QuestionDateField';
import { QuestionCheckbox } from '../Onboarding/QuestionCheckbox';
import { QuestionCheckboxes } from '../Onboarding/QuestionCheckboxes';
import { QuestionSelect } from '../Onboarding/QuestionSelect';

export const SignUp = (props) => {
  const { form_authenticity_token, submit_profile_path } = props;
  const [state, setState] = React.useState(
    // initialState(profile_data, QuestionsInit({}))
    {}
  );
  const Questions = QuestionsInit({ setState, state });

  return (
    <div>
      <h2 className="text-center">Sign Up</h2>
      {/* Form Authenticity Token */}
      <input type="hidden" name="authenticity_token" value={form_authenticity_token} />
      {/* Questions */}
      {Questions.map((question, index) => {
        // If there are show conditions, check if the question should be shown, otherwise return true
        const shouldShow = question.showConditions
          ? question.showConditions.every(condition => condition.showCondition() === true)
          : true;

        if (shouldShow === false) {
          return <></>;
        } else if (question.questionType === 'text') {
          return <QuestionTextField key={index} question={question} setState={setState} state={state} />;
        } else if (question.questionType === 'date') {
          return <QuestionDateField key={index} question={question} setState={setState} state={state} />;
        } else if (question.questionType === 'checkbox') {
          return <QuestionCheckbox key={index} question={question} setState={setState} state={state} />;
        } else if (question.questionType === 'checkboxes') {
          return <QuestionCheckboxes key={index} question={question} setState={setState} state={state} />;
        } else if (question.questionType === 'select') {
          return <QuestionSelect key={index} question={question} setState={setState} state={state} />;
        } else if (question.questionType === 'jsx') {
          return question.content;
        }
      })}

      {/* Submit Button */}
      {/* <button type="submit" className="btn btn-primary">Submit</button> */}
    </div>
  );
}


export interface ErrorCondition {
  errorContent: string;
  condition: (value: string) => boolean;
}
export interface ShowCondition {
  showCondition: boolean;
}
export interface Option {
  value: string;
  label: string;
}
export interface Question {
  label: string;
  name: string;
  errorConditions: ErrorCondition[];
  questionType: string;
  description?: string;
  showConditions?: ShowCondition[];
  options?: Option[];
}

const QuestionsInit = ({ setState, state }: { setState?: any, state?: any }) => {
  console.log("Updating Questions with", state);
  return [
    // 1.	“Name of Reporting Company:” (text field)
    {
      label: 'Name of Reporting Company',
      name: 'user[company_information][company_name]',
      errorConditions: [
        {
          errorContent: 'Please enter the name of the reporting company.',
          condition: (value: string) => value.length === 0,
          blocking: true
        },
      ],
      questionType: 'text'
    },
    // Date of Incorporation/Formation: (date field)
    {
      label: 'Date of Incorporation/Formation',
      name: 'user[company_information][date_of_incorporation]',
      errorConditions: [
        // If the current date is 45-60 days after the date entered in this section (i.e., 30-45 days from the Reporting Company’s 90-day filing deadline), the system will display a warning message to the user.
        {
          errorContent: 'Notice: Based on the information provided, the deadline to submit this Reporting Company’s initial BOI Report with FinCEN is coming up soon.  In order to guarantee that the Reporting Company’s beneficial ownership information (“BOI”) will be submitted to FinCEN before such filing deadline, all relevant BOI must be entered into the Teeple Hall, LLP CTA Portal at least 30 days before such filing deadline.',
          condition: (value: string) => {
            const date = new Date(value);
            const today = new Date();
            const days = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);
            return days >= 45 && days <= 60;
          },
          blocking: false
        },
        // If the current date is over 60 days after the date entered in this section (i.e., 30 days or less from the Reporting Company’s 90-day filing deadline)
        {
          errorContent: 'Notice: Based on the information provided, there is less than 30 days before the Reporting Company’s 90-day filing deadline.  Due to the limited time before such deadline, please be advised that the Teeple Hall, LLP CTA Portal cannot guarantee that it will file the Reporting Company’s initial BOI Report in before the date of such deadline.',
          condition: (value: string) => {
            const date = new Date(value);
            const today = new Date();
            const days = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);
            return days > 60;
          },
          blocking: false
        }
      ],
      questionType: 'date'
    },
    // If the current date is over 60 days after the date entered in this section, display Acknowledgement of Notice checkbox
    {
      label: 'I acknowledge the notice',
      name: 'user[company_information][acknowledge_notice]',
      errorConditions: [
        {
          errorContent: 'Please acknowledge the notice.',
          condition: (value: string) => value.length === 0,
          blocking: true
        }
      ],
      questionType: 'checkbox',
      showConditions: [
        {
          showCondition: () => {
            const date = new Date(
              state['user[company_information][date_of_incorporation]']
            );
            const today = new Date();
            const days = (today.getTime() - date.getTime()) / (1000 * 3600 * 24);
            return days > 60;
          }
        }
      ]
    },
    // New Section
    {
      questionType: 'jsx',
      content: (
        <div>
          <h3 className="text-center">CTA Compliance Representative Information</h3>
        </div>
      )
    },
    // Email
    {
      label: 'Email',
      name: 'user[email]',
      errorConditions: [
        {
          errorContent: 'Please enter a valid email address.',
          condition: (value: string) => !value.includes('@'),
          blocking: true
        },
      ],
      questionType: 'text'
    },
    // Phone Number
    {
      label: 'Phone Number',
      name: 'user[profile][rep_phone]',
      errorConditions: [
        {
          errorContent: 'Please enter a valid phone number.',
          condition: (value: string) => value.length === 0,
          blocking: true
        },
      ],
      questionType: 'text'
    },
    // first name
    {
      label: 'First Name',
      name: 'user[profile][rep_first_name]',
      errorConditions: [
        {
          errorContent: 'Please enter the first name of the compliance representative.',
          condition: (value: string) => value.length === 0,
          blocking: true
        },
      ],
      questionType: 'text'
    },
    // Middle Name
    {
      label: 'Middle Name',
      name: 'user[profile][rep_middle_name]',
      errorConditions: [],
      questionType: 'text'
    },
    // Last Name
    {
      label: 'Last Name',
      name: 'user[profile][rep_last_name]',
      errorConditions: [
        {
          errorContent: 'Please enter the last name of the compliance representative.',
          condition: (value: string) => value.length === 0,
          blocking: true
        },
      ],
      questionType: 'text'
    },
    // Suffix
    {
      label: 'Suffix',
      name: 'user[profile][rep_suffix]',
      errorConditions: [],
      questionType: 'text'
    },

    // b.	Disclaimer  with required checkbox underneath #4 stating: 
    // i.	“Important: Only persons authorized to sign on behalf of the Reporting Company may execute the forthcoming Agreement.By clicking this checkbox, you are stating that you have the authority to bind the Reporting Company to the forthcoming Agreement.”
    {
      label: 'Important: Only persons authorized to sign on behalf of the Reporting Company may execute the forthcoming Agreement. By clicking this checkbox, you are stating that you have the authority to bind the Reporting Company to the forthcoming Agreement.',
      name: 'user[profile][signature_authorization]',
      errorConditions: [
        {
          errorContent: 'Please acknowledge the disclaimer.',
          condition: (value: string) => value.length === 0,
          blocking: true
        }
      ],
      questionType: 'checkbox'
    },
    // •	Have checkbox with statement such as “By continuing, the Reporting Company agrees to the Terms and Conditions and Privacy Policy of this site.” 
    {
      label: (
        <>By continuing, the Reporting Company agrees to the <a href="/terms" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a> of this site.</>
      ),
      name: 'user[profile][terms]',
      errorConditions: [
        {
          errorContent: 'Please agree to the terms and conditions.',
          condition: (value: string) => value == 'false',
          blocking: true
        }
      ],
      questionType: 'checkbox'
    },

    // Submit Button
    {
      questionType: 'jsx',
      content: (
        <div className="text-center">
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      ),
    }
  ];
}